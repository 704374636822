/* eslint-disable */
import { React } from "react";
import $ from "jquery";

function TutorialText(props) {
  const { type } = props;
  $("#transcript_toggle_button").click(function (ev) {
    ev.preventDefault();

    if ($(this).text() === "Show transcript") {
      $(this).text("Hide transcript");
      $("#transcript_container").show();
    } else {
      $(this).text("Show transcript");
      $("#transcript_container").hide();
    }
  });
  switch (type) {
    case "browseData":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about browsing data in the Open Data Catalog"
              src="https://www.youtube.com/embed/R4-09bZLn_w"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <h3>How to browse datasets in the Data Catalog</h3>
            </p>
            <p>
              0:11
              <br />
              In this video, I will show you how to browse datasets in our Data
              Catalog.
            </p>

            <p>
              0:17
              <br />
              The Data Catalog contains a wide range of datasets available
              through our API, and you can use various filters to find the
              specific data you need.
            </p>

            <p>
              0:28
              <br />
              From the Open Data Portal homepage, you can click on the Data
              Catalog icon.
            </p>

            <p>
              0:33
              <br />
              Here you will find an inventory of all datasets available in our
              API.
            </p>

            <p>
              0:40
              <br />
              In the Data Catalog, you will notice the sidebar on the left-hand
              side of the screen.
            </p>

            <p>
              0:45
              <br />
              This sidebar contains filters that you can apply one at a time to
              refine your search.
            </p>

            <p>
              0:52
              <br />
              You can filter datasets by categories such as Child Labor,
              Enforcement, Training, and Workforce.
            </p>

            <p>
              1:01
              <br />
              Simply click on a category to see datasets related to that topic.
            </p>

            <p>
              1:07
              <br />
              Tags provide additional descriptors for datasets.
            </p>

            <p>
              1:11
              <br />
              You can use tags like accidents, fatalities, or other specific
              terms related to your search interest.
            </p>

            <p>
              1:21
              <br />
              If you are looking for datasets from a specific agency within the
              Department of Labor, you can filter by agency name using the
              agency filter.
            </p>

            <p>
              1:33
              <br />
              Once you have applied a filter, you can further refine your
              results by sorting the dataset names.
            </p>

            <p>
              1:42
              <br />
              By default, dataset names are sorted in ascending order, but you
              can change it to descending order if you prefer.
            </p>

            <p>
              1:53
              <br />
              After applying a filter and a sort, browse through the datasets
              listed.
            </p>

            <p>
              1:59
              <br />
              Each dataset entry provides a brief description to help you
              understand its contents.
            </p>

            <p>
              2:07
              <br />
              Once you find what you are looking for, click on the dataset name
              to access the dataset’s metadata and data.
            </p>

            <p>
              2:16
              <br />
              Now you know how to efficiently navigate our Data Catalog to find
              the datasets you need.
            </p>

            <p>
              2:23
              <br />
              Find more videos like this on our Video Tutorials page and don't
              forget to like and subscribe to our channel.
            </p>
          </div>
        </>
      );

    case "registerApiKey":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about registering for an API Key"
              src="https://www.youtube.com/embed/r5xLq_o9pro"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <h3>
                How to register for an API key to access metadata and data
              </h3>
            </p>
            <p>
              0:12
              <br />
              In this video, I will guide you through the steps to get an API
              key to access our metadata and data.
            </p>

            <p>
              0:19
              <br />
              From the Open Data Portal homepage, click on the Sign In or
              Register link.
            </p>

            <p>
              0:26
              <br />
              Here you will find a login.gov button you can click on to create
              an account.
            </p>

            <p>
              0:32
              <br />
              Next click Create an Account and enter your e-mail address.
            </p>

            <p>
              0:41
              <br />
              Read and accept the login.gov Rules of Use and click the submit
              button.
            </p>

            <p>
              0:51
              <br />
              Then follow the instructions in the e-mail sent to you to confirm
              your e-mail address.
            </p>

            <p>
              1:03
              <br />
              Now we are going to create a strong password that is 12 characters
              or longer.
            </p>

            <p>
              1:09
              <br />
              Next we are going to set up two factor authentication for the
              security of your account.
            </p>

            <p>
              1:17
              <br />
              There are several options and you can choose the one that works
              best for you.
            </p>

            <p>
              1:22
              <br />
              The text message authentication method is very easy, so I will
              show you how to set that up in this demo.
            </p>

            <p>
              1:32
              <br />
              First, enter your mobile number in the provided field.
            </p>

            <p>
              1:37
              <br />
              Then click on Send Code to receive a text message verification
              code to your mobile phone.
            </p>

            <p>
              1:45
              <br />
              Next, check your text messages for the verification code and enter
              the verification code you received into the corresponding field on
              the login.gov website.
            </p>

            <p>
              1:58
              <br />
              Once you click on submit to confirm the code, your two factor
              authentication will be activated.
            </p>

            <p>
              2:06
              <br />
              You can add additional authentication methods if you would like,
              but I am going to skip this for now.
            </p>

            <p>
              2:15
              <br />
              Finally, click Agree and continue.
            </p>

            <p>
              2:20
              <br />
              You will then be redirected to the Open Data Portal's API
              Registration Form.
            </p>

            <p>
              2:27
              <br />
              The next step is to fill out the questionnaire.
            </p>

            <p>
              2:30
              <br />
              The Department of Labor will use your information to prioritize
              the creation of tools and resources on the Open Data Portal for
              using the API.
            </p>

            <p>
              2:41
              <br />
              Then agree to the Terms of Service and click the register for an
              API Key button.
            </p>

            <p>
              2:50
              <br />
              Next, click on Go to API Keys to be redirected to the Open Data
              Portal's API Key Manager.
            </p>

            <p>
              3:00
              <br />
              There may be a short delay while the system incorporates your
              credentials, and you may need to wait for a few minutes and or
              refresh your screen before an API key is generated in your
              account.
            </p>

            <p>
              3:15
              <br />
              Your API key will look like a mixture of numbers, letters, and
              special characters.
            </p>

            <p>
              3:20
              <br />
              You can create additional keys, up to five, if you need to use
              them for different purposes, client applications, or automation.
            </p>

            <p>
              3:32
              <br />
              Now you are all set to explore our metadata and data using your
              API key.
            </p>

            <p>
              3:38
              <br />
              Thank you for registering and happy data discovery!
            </p>

            <p>
              3:42
              <br />
              Find more videos like this on our Video Tutorials page and don't
              forget to like and subscribe to our channel.
            </p>
          </div>
        </>
      );

    case "queryBuilder":
      return (
        <>
          <div className="row" id="video_player">
            <iframe
              width="700"
              height="394"
              title="YouTube video about accessing data using the API Query Builder"
              src="https://www.youtube.com/embed/_Ha94qFG7kU"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p style={{ align: "center" }}>
            <a href="#transcript" id="transcript_toggle_button">
              Show transcript
            </a>
          </p>
          <div id="transcript_container" style={{ display: "none" }}>
            <p>
              <h3>How to access data with the API Query Builder</h3>
            </p>
            <p>
              0:11
              <br />
              In this video, I will guide you through using the API Query
              Builder to effortlessly request data from the API.
            </p>

            <p>
              0:19
              <br />
              Before we get started, be sure that you have registered for an
              account on the Open Data Portal and are signed in.
            </p>

            <p>
              0:26
              <br />
              If you need help with the registration process, check out the
              video description for a link to a tutorial that can help you with
              that.
            </p>

            <p>
              0:35
              <br />
              To begin, navigate to any dataset page from the Data Catalog.
            </p>

            <p>
              0:40
              <br />
              For this demonstration, I will use an API training dataset.
            </p>

            <p>
              0:46
              <br />
              The dataset name is Training dataset (industries).
            </p>

            <p>
              0:51
              <br />
              First, let's learn more about our dataset.
            </p>

            <p>
              0:55
              <br />
              Scroll down to the Fields table and you will see that this dataset
              has three fields called Industry, Value and Year.
            </p>

            <p>
              1:06
              <br />
              Next, scroll down to the Data Preview.
            </p>

            <p>
              1:09
              <br />
              This will give you an idea of what the data look like before you
              make a request.
            </p>

            <p>
              1:16
              <br />
              Finally, scroll down to the API Query Builder, which provides you
              with a basic template for making API calls and allows you to
              interactively select which fields and records you want, choose how
              records are formatted and sorted, and generates an API request
              that you can use to get your desired data.
            </p>

            <p>
              1:39
              <br />
              Because you are already signed into the portal, the Authorize
              button is green, which indicates that your API key is in use and
              will be automatically populated into your data requests.
            </p>

            <p>
              1:53
              <br />
              If you were not signed in, the Authorize button would be red,
              which would indicate that you would not be able to request data.
            </p>

            <p>
              2:02
              <br />
              Let's start building an API request.
            </p>

            <p>
              2:06
              <br />
              Begin by expanding the API Query Builder to view the available
              parameters for customizing your data request.
            </p>

            <p>
              2:14
              <br />
              FORMAT allows you to pick a data format returned by the API.
            </p>

            <p>
              2:21
              <br />
              By default you will get JSON, but you can get CSV or XML instead.
            </p>

            <p>
              2:28
              <br />I am going to pick CSV because it is the most efficient
              output format and is widely usable in most software applications.
            </p>

            <p>
              2:38
              <br />
              LIMIT allows you to specify the maximum number of records returned
              by the API.
            </p>

            <p>
              2:45
              <br />
              By default you will get 10 records, but you can request up to 5
              megabytes of data, not exceeding 10,000 records per request.
            </p>

            <p>
              2:55
              <br />I am going to leave it at 10.
            </p>

            <p>
              2:58
              <br />
              OFFSET allows you to specify the number of records to skip from
              the top of a dataset.
            </p>

            <p>
              3:04
              <br />
              This parameter can be used in conjunction with the LIMIT parameter
              when you are requesting more than the maximum record limit for a
              dataset.
            </p>

            <p>
              3:13
              <br />
              Since we are not requesting more than the maximum record limit, we
              can leave this at 0.
            </p>

            <p>
              3:21
              <br />
              FIELDS allows you to select specific fields for your request.
            </p>

            <p>
              3:25
              <br />
              For example, if you only want industry and value, you can select
              these fields.
            </p>

            <p>
              3:33
              <br />
              This can be very useful at delivering only the data elements you
              need and reducing the size of data payloads when you are working
              with big datasets that have lots of variables.
            </p>

            <p>
              3:46
              <br />
              If you want all fields, don't make any selections because by
              default all fields will be returned.
            </p>

            <p>
              3:55
              <br />
              SORT allows you to sort records in ascending or descending order.
            </p>

            <p>
              4:01
              <br />I am going to use the default setting which is set to
              ascending order.
            </p>

            <p>
              4:07
              <br />
              SORT BY allows you to sort records by a single field.
            </p>

            <p>
              4:12
              <br />
              In this example I will sort the records by year.
            </p>

            <p>
              4:19
              <br />
              FILTER OBJECT allows you to get a subset of records from the API.
            </p>

            <p>
              4:26
              <br />
              Simply pick a pre-populated filter example and edit it.
            </p>

            <p>
              4:33
              <br />
              The filter operators we support are equals, not equals, greater
              than, less than, in, not in, and like. For this example, let's
              pick equals and filter the dataset records where industry equals
              A.
            </p>

            <p>
              4:54
              <br />
              Next, replace the text “YOUR_FIELD” with industry and “YOUR_VALUE”
              with A.
            </p>

            <p>
              5:04
              <br />
              Now, we are ready to execute the API query.
            </p>

            <p>
              5:11
              <br />
              If everything is successful, you will see data in the response
              body as well as the response code 200.
            </p>

            <p>
              5:20
              <br />
              You will have the option to download the data once directly from
              the response body, or you can use a curl command or request URL to
              automate workflows.
            </p>

            <p>
              5:32
              <br />
              This allows you to regularly retrieve your customized data
              requests from the API with the latest available data from our
              database.
            </p>

            <p>
              5:41
              <br />
              This feature distinguishes our API from other data download
              methods.
            </p>

            <p>
              5:47
              <br />I am going to use the request URL which can be executed
              within a web browser or external software like Python, R, Excel
              and dashboarding applications.
            </p>

            <p>
              6:00
              <br />
              For simplicity, copy and paste the request URL into a web browser
              and get your CSV data.
            </p>

            <p>
              6:14
              <br />
              Because I have Microsoft Excel on my computer, I can open up this
              CSV file download as a tabular spreadsheet.
            </p>

            <p>
              6:26
              <br />
              Now you know how to use the API Query Builder to access our data.
            </p>

            <p>
              6:31
              <br />
              Try modifying the parameters and re-running the request on your
              own.
            </p>

            <p>
              6:36
              <br />
              Find more videos like this on our Video Tutorials page and don't
              forget to like and subscribe to our channel.
            </p>
          </div>
        </>
      );

    default:
  }
}

export default TutorialText;
